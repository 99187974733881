import React from 'react'
import { Link, navigate } from 'gatsby'

import Layout from '../components/layout'
// import Image from '../components/image'
import SEO from '../components/seo'
import './featured-projects.less'

import MetaImage from '../images/og/projects.jpg'

import Eras from '../images/projects/project-main-eras.jpg'
import EDI from '../images/projects/project-main-edi.jpg'
import Arcurve from '../images/projects/project-main-arcurve.jpg'
import WorkplaceOne from '../images/projects/project-main-workplace-one.jpg'

let progress = false

let is_scrolling = false
let wheeling = null

const key_next_array = [38, 39, 32, 13]
const key_prev_array = [37, 40]

class Project extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      current: 1,
      total: 3,
      touchStart: 0,
      content_status: '',
    }
  }

  goTo = (e, link) => {
    e.preventDefault()
    document.body.classList.add('cover')
    setTimeout(() => {
      navigate(link)
    }, 700)
  }

  get = (q) => {
    let get = {}
    if (document.location.toString().indexOf('?') !== -1) {
      var query = document.location
        .toString()
        // get the query string
        .replace(/^.*?\?/, '')
        // and remove any existing hash string (thanks, @vrijdenker)
        .replace(/#.*$/, '')
        .split('&')

      for (var i = 0, l = query.length; i < l; i++) {
        var aux = decodeURIComponent(query[i]).split('=')
        get[aux[0]] = aux[1]
      }
    }
    if (q) {
      return get[q]
    }
    return get
  }

  wheel = (e) => {
    // e.preventDefault();

    if (typeof window !== 'undefined' && window.innerWidth < 960) {
      return
    }

    if (!is_scrolling) {
      is_scrolling = true

      if (e.deltaY > 0) {
        setTimeout(() => {
          if (is_scrolling) {
            this.next_slide()
          }
        }, 100)
      } else {
        setTimeout(() => {
          if (is_scrolling) {
            this.prev_slide()
          }
        }, 100)
      }
    }

    clearTimeout(wheeling)
    wheeling = setTimeout(function () {
      is_scrolling = false
    }, 50)
  }

  next_slide = () => {
    if (progress === false) {
      progress = true

      if (this.state.current < this.state.total) {
        let next = this.state.current
        next++
        this.setState({ current: next })
      } else {
        this.setState({ current: 1 })
      }

      setTimeout(() => {
        progress = false
        setInterval(() => {
          this.detect_scrolling()
        }, 50)
      }, 750)
    }
  }

  prev_slide = () => {
    if (progress === false) {
      progress = true

      if (this.state.current > 1) {
        let next = this.state.current
        next--
        this.setState({ current: next })
      } else {
        this.setState({ current: this.state.total })
      }

      setTimeout(() => {
        progress = false
        setInterval(() => {
          this.detect_scrolling()
        }, 50)
      }, 750)
    }
  }

  detect_scrolling = () => {
    if (!is_scrolling) {
      is_scrolling = false
    }
  }

  handleKeyDown = (event) => {
    if (key_next_array.includes(event.keyCode)) {
      this.next_slide()
    }
    if (key_prev_array.includes(event.keyCode)) {
      this.prev_slide()
    }
  }

  componentDidMount = () => {
    document.body.classList.add('remove')
    setTimeout(() => {
      document.body.classList.remove('cover', 'remove', 'white')
    }, 800)
    setTimeout(() => {
      this.setState({ content_status: 'visible' })
      this.setState({ current: 1 })
    }, 700)
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('scroll', this.wheel)
    window.addEventListener('resize', this.setViewHeight)

    if (this.get('id')) {
      if (this.get('id') <= this.state.total) {
        this.setState({ current: parseInt(this.get('id')) })
      }
    }
    this.setViewHeight()
  }

  setViewHeight = () => {
    if (typeof window !== 'undefined') {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', vh + 'px')
    }
  }

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleTouchStart = (e) => {
    this.setState({ touchStart: e.changedTouches[0].clientX })
  }
  handleTouchEnd = (e) => {
    if (this.state.touchStart - e.changedTouches[0].clientX >= 60) {
      this.next_slide()
    } else if (this.state.touchStart - e.changedTouches[0].clientX < -60) {
      this.prev_slide()
    }
  }
  render() {
    return (
      <Layout header_logo="dark" header_menu="light">
        <SEO
          title="Work"
          image={MetaImage}
          description="Explore Enso Digital’s most recent work, where meaningful customer experiences help transform client business."
        />

        <section
          className="project-container"
          onWheel={this.wheel}
          onTouchMove={(e) => e.preventDefault()}
          onTouchStart={this.handleTouchStart}
          onTouchEnd={this.handleTouchEnd}
          data-uk-scrollspy="cls:uk-animation-fade"
        >
          <span className={'backgrounds ' + this.state.content_status}>
            {/* <span
              className={this.state.current === 1 ? 'active' : ''}
              style={{ background: '#478261' }}
            ></span> */}
            <span
              className={this.state.current === 1 ? 'active' : ''}
              style={{ background: '#478261' }}
            ></span>
            <span
              className={this.state.current === 2 ? 'active' : ''}
              style={{ background: '#000EE1' }}
            ></span>
            <span
              className={this.state.current === 3 ? 'active' : ''}
              style={{ background: '#008EAA' }}
            ></span>
          </span>
          <div className="uk-container">
            <div className="uk-grid uk-flex uk-flex-middle viewport">
              <div className="uk-width-3-5@m">
                <div
                  className={
                    'uk-grid hide-on-start ' + this.state.content_status
                  }
                  data-uk-height-match
                >
                  <div className="uk-width-1-6@m uk-flex uk-flex-middle uk-visible@m">
                    <p className="project-pagination">
                      <span className="number-container">
                        <span
                          className={this.state.current === 1 ? 'active' : ''}
                        >
                          01
                        </span>
                        <span
                          className={this.state.current === 2 ? 'active' : ''}
                        >
                          02
                        </span>
                        <span
                          className={this.state.current === 3 ? 'active' : ''}
                        >
                          03
                        </span>
                        {/* <span
                          className={this.state.current === 4 ? 'active' : ''}
                        >
                          04
                        </span> */}
                      </span>{' '}
                      &ndash;{' '}
                      <span className="total">
                        {this.state.total.toString().padStart(2, '0')}
                      </span>
                      <span
                        className="arrows prev"
                        onClick={this.prev_slide}
                      ></span>
                      <span
                        className="arrows next"
                        onClick={this.next_slide}
                      ></span>
                    </p>
                  </div>
                  <div className="uk-width-5-6@m">
                    <div className="text-container uk-flex uk-flex-middle@m viewport">
                      <p className="project-pagination uk-hidden@m">
                        <span className="number-container">
                          <span
                            className={this.state.current === 1 ? 'active' : ''}
                          >
                            01
                          </span>
                          <span
                            className={this.state.current === 2 ? 'active' : ''}
                          >
                            02
                          </span>
                          <span
                            className={this.state.current === 3 ? 'active' : ''}
                          >
                            03
                          </span>
                          {/* <span
                            className={this.state.current === 4 ? 'active' : ''}
                          >
                            04
                          </span> */}
                        </span>{' '}
                        &ndash;{' '}
                        <span className="total">
                          {this.state.total.toString().padStart(2, '0')}
                        </span>
                        <span
                          className="arrows prev"
                          onClick={this.prev_slide}
                        ></span>
                        <span
                          className="arrows next"
                          onClick={this.next_slide}
                        ></span>
                      </p>
                      {/* <div className={this.state.current === 1 ? 'active' : ''}>
                        <h1>Workplace One</h1>
                        <Link
                          className="project-link"
                          onClick={(e) =>
                            this.goTo(e, '/featured-projects/workplace-one')
                          }
                          to="/featured-projects/workplace-one"
                        >
                          View Project
                        </Link>
                      </div> */}
                      <div className={this.state.current === 1 ? 'active' : ''}>
                        <h1>Workplace One</h1>
                        <Link
                          className="project-link"
                          onClick={(e) =>
                            this.goTo(e, '/featured-projects/workplace-one')
                          }
                          to="/featured-projects/workplace-one"
                        >
                          View Project
                        </Link>
                      </div>
                      <div className={this.state.current === 2 ? 'active' : ''}>
                        <h1>ERAS</h1>
                        <Link
                          className="project-link"
                          onClick={(e) =>
                            this.goTo(e, '/featured-projects/eras')
                          }
                          to="/featured-projects/eras"
                        >
                          View Project
                        </Link>
                      </div>
                      <div className={this.state.current === 3 ? 'active' : ''}>
                        <h1>Everyday Icing</h1>
                        <Link
                          className="project-link"
                          onClick={(e) =>
                            this.goTo(e, '/featured-projects/everyday-icing')
                          }
                          to="/featured-projects/everyday-icing"
                        >
                          View Project
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'img-container ' + this.state.content_status}>
            <div className="inner">
              <img
                src={WorkplaceOne}
                alt="ERAS"
                className={this.state.current === 1 ? 'active' : ''}
              />
              <img
                src={Eras}
                alt="ERAS"
                className={this.state.current === 2 ? 'active' : ''}
              />
              <img
                src={EDI}
                alt="Everyday Icing"
                className={this.state.current === 3 ? 'active' : ''}
              />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Project
